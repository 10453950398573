import { DartSystem } from 'src/app/shared/models/dart-systems';

export class User {
    public name: string;
    public sub: string;
    public email: string;
    public photoUrl: string;
    public systems: DartSystem[];

    constructor(name: string, sub: string, email: string, photoUrl?: string, systems?: Partial<DartSystem>[]) {
        this.name = name;
        this.sub = sub;
        this.email = email;
        this.photoUrl = photoUrl ?? 'https://s3.us-east-2.amazonaws.com/billpocket-public-assets/apps/default.svg';
        this.systems = DartSystem.initArray(systems ?? []);
    }
}
