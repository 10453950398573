export class DartSystem {
    public alias: string;
    public description: string;
    public name: string;
    public frontUrl: string;
    public imageUrl: string;

    constructor(system: Partial<DartSystem>) {
        Object.assign(this, system);
        this.imageUrl = system.imageUrl ?? 'https://s3.us-east-2.amazonaws.com/billpocket-public-assets/apps/default-icon-min.png';
    }

    static initArray(systems: Partial<DartSystem>[]): DartSystem[] {
        return systems.map((system) => new DartSystem(system));
    }
}
