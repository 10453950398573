export const environment = {
    production: true,
    CONSTANTS: {
        DART_SERVICE: 'https://ke21js058c.execute-api.us-east-1.amazonaws.com/development'
    },
    AWS: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_cAthijTIr',
        USER_POOL_WEB_CLIENT_ID: '2e3a8ajnal51e5jkckra51ocd7',
        USER_IDENTITY_POOL_ID: 'us-east-1:728cbe48-1562-4aba-8966-d23f36b64d1e',
        OAUTH: {
            DOMAIN: 'dart-bp-internal-development.auth.us-east-1.amazoncognito.com',
            REDIRECT_SIGN_IN: 'https://gauth.billpocket.dev',
            REDIRECT_SIGN_OUT: 'https://gauth.billpocket.dev',
        }
    },
    COOKIE_STORAGE: {
        DOMAIN: 'billpocket.dev',
        PATH: '/',
        EXPIRES: 1,
        SECURE: false,
    },
};
