import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private showing = false;

    constructor() { }

    get isShowing(): boolean {
        return this.showing;
    }

    public show(): void {
        this.showing = true;
    }

    public hide(): void {
        this.showing = false;
    }

}
