import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { User } from 'src/app/shared/models/user';
import { DartService } from 'src/app/shared/services/dart.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user: User;

    constructor(private dart: DartService) {
    }

    /**
     * Revisa si el usuario está autenticado y lo inicializa
     */
    public async checkSession(): Promise<boolean> {
        const user = await Auth.currentUserInfo();
        if (!user) {
            return false;
        }
        this.user = new User(
            user.attributes.name,
            user.attributes.sub,
            user.attributes.email,
            user.attributes.picture,
            await this.dart.getUserSystems(await this.getToken()),
        );
        return true;
    }

    public async getToken(): Promise<string> {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    }

    public async signIn(): Promise<void> {
        await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google
        });
    }

    public async signOut(): Promise<void> {
        await Auth.signOut();
    }
}
