import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomBreakpointsProvider } from './shared/material/custom-breakpoints';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/es-MX';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        HttpClientModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-MX' },
        CustomBreakpointsProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
