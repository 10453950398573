import { environment } from './environments/environment';

const config = {
    Auth: {
        region: environment.AWS.REGION,
        userPoolId: environment.AWS.USER_POOL_ID,
        userPoolWebClientId: environment.AWS.USER_POOL_WEB_CLIENT_ID,
        identityPoolId: environment.AWS.USER_IDENTITY_POOL_ID,
        cookieStorage: {
            domain: environment.COOKIE_STORAGE.DOMAIN,
            path: environment.COOKIE_STORAGE.PATH,
            expires: environment.COOKIE_STORAGE.EXPIRES,
            secure: environment.COOKIE_STORAGE.SECURE,
        },
        oauth: {
            domain: environment.AWS.OAUTH.DOMAIN,
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: environment.AWS.OAUTH.REDIRECT_SIGN_IN,
            redirectSignOut: environment.AWS.OAUTH.REDIRECT_SIGN_OUT,
            responseType: 'code',
        },
    },
};

export default config;
