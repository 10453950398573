import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Cookies } from 'src/app/shared/utils/cookies.util';
import { LoaderService } from '../services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private router: Router,
        private loader: LoaderService,
    ) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.loader.show();
        const isLoginRoute = state.url.indexOf('/login') >= 0;
        try {
            if (await this.auth.checkSession()) {
                const redirectTo: string = Cookies.get('bpRedirectTo');
                if (redirectTo) {
                    Cookies.remove('bpRedirectTo');
                    window.location.href = redirectTo;
                } else if (isLoginRoute) {
                    this.router.navigateByUrl('/suite');
                } else if (state.url === '/suite') {
                    return true;
                }
            } else if (!isLoginRoute) {
                this.router.navigateByUrl('/login');
            }
            return isLoginRoute;
        } catch (error) {
            console.error('No se pudo verificar autenticación', error);
            if (isLoginRoute) {
                return true;
            }
            this.router.navigateByUrl('/login');
        } finally {
            this.loader.hide();
        }
    }
}

