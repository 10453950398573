import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DartResponse, DartUserSystems } from 'src/app/shared/interfaces/dart.interface';

@Injectable({
    providedIn: 'root'
})
export class DartService {
    private serviceUrl = environment.CONSTANTS.DART_SERVICE;

    constructor(private http: HttpClient) {
    }

    public async getUserSystems(authToken: string): Promise<DartUserSystems[]> {
        try {
            const { data } = await this.http.get<DartResponse>(`${this.serviceUrl}/api/v1/user/systems`, {
                headers: {
                    Authorization: authToken,
                }
            }).toPromise();
            return data;
        } catch (e) {
            return null;
        }
    }
}
