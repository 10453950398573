import { environment } from 'src/environments/environment';

export class Cookies {
    private static readonly path = environment.COOKIE_STORAGE.PATH;
    private static readonly domain = environment.COOKIE_STORAGE.DOMAIN;
    private static readonly expires = environment.COOKIE_STORAGE.EXPIRES;

    static set(key: string, value: string, options?: OptionsSet): void {
        options = Object.assign({
            path: this.path,
            domain: this.domain,
            expires: this.getExpirationDate(this.expires)
        }, options);
        document.cookie = `${key}=${value}; path=${options.path}; expires=${options.expires}; domain=${options.domain};`;
    }

    static remove(key: string, options?: OptionsRemove): void {
        options = Object.assign({
            path: this.path,
            domain: this.domain,
        }, options);
        document.cookie = `${key}=; path=${options.path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${options.domain};`;
    }

    static get(key: string): string {
        const name = key + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let cookie of cookieArray) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    }

    static getExpirationDate(days: number): string {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        return date.toUTCString();
    }
}


export interface OptionsSet {
    path?: string;
    domain?: string;
    expires?: string;
}

export interface OptionsRemove {
    path?: string;
    domain?: string;
}
